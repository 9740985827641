const Tab = (props)=>{
    return(
        <>
       <div class="wrapper">
            <div class="tabs_wrap">
                <ul>
                <li data-tabs="male" class="active"><div class="align"><img src="./Assets/Images/Lock.png" /> <span>7:50</span></div></li>
                <li data-tabs="female"><span class="align"><img src="./Assets/Images/Profile.png" />SUPERVISED</span></li>
                <li data-tabs="both"><span class="align"><img src="./Assets/Images/Unlock.png" />UNLOCK</span></li>
                </ul>
            </div>
        </div>
            
        </>
    )
}

export default Tab; 