const Avtar = (props)=>{
    return(
        <>
       <div class="av">
            <div class="av_wrap">
               <img src="./Assets/Images/avtar.png" />    
               <h2>Crazy-cat</h2>
            </div>
            <div class="av_setting">
              <img src="./Assets/Images/setting.png" />    
            </div>
        </div>
            
        </>
    )
}

export default Avtar; 