import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./Pages/Home";
import Video from "./Pages/Video";
import Videoquiz from "./Pages/Videoquiz";

const App = () => {
  return(
    <Router>
      <Routes>
          <Route  exact  path="/Home" element={<Home />}/>
          <Route  exact  path="/" element={<Video />}/>
          <Route  exact  path="/Videoquiz" element={<Videoquiz />}/>
     </Routes>   
    </Router>
  );
};
export default App;
