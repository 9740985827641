import React from "react";
import {Link} from 'react-router-dom'
import Carousel from "react-elastic-carousel";
import Tab from "../Components/Tab";
import Cookiesunlock from "../Components/Cookiesunlock";
import Card from "../Components/Card";
// import Arithmetic from './Assets/Images/Arithmetic.png';

const Videoquiz = () => {
    const carouselRef = React.useRef(null);
    let resetTimeout;
    const breakPoints =[
      {width:1, itemsToShow:2},
      {width:500, itemsToShow:2},
      {width:768, itemsToShow:4},
      {width:1200, itemsToShow:4}
    ];
  return (
    <>
      <div
        className=""
        style={{
          backgroundColor: "#f5f5f5",
          borderRadius: 10,
          padding: "18px 20px",
          width: "40%",
          margin: "auto",
          
        }}
      >
        <Tab /> 
        <div class="cat_sub">
        <Link to="/">
          <img src="./Assets/Images/Arrowleft.png" alt="Back" /></Link>
          <h4 class="cat_subname">Video Quiz</h4>
        </div>
        <div>
        <div class="cat_heading">
         <h4 class="cat_name">Arithmetic</h4>
          <h5 class="see_more">See all</h5>
        </div>

        <Carousel
          ref={carouselRef}
         breakPoints={breakPoints}
         pagination={false} 
         enableAutoPlay 
         autoPlaySpeed={4000} 
         showArrows={false}
         onNextEnd={({ index }) => {
          clearTimeout(resetTimeout);
          resetTimeout = setTimeout(() => {
              carouselRef?.current?.goTo(0);
          }, 4000); // same time
        }}
         > 
        <Card i_des="Measuring!" i_time="01:12"/>
        <Card i_des="Rounding Numbers for Kids" i_time="01:24"/>
        <Card i_des="Perimeter for Kids" i_time="01:24"/>
        <Card i_des="Measuring!" i_time="01:24"/>
        <Card i_des="Measuring!" i_time="01:24"/>
       </Carousel>
       </div>


       <div>
        <div class="cat_heading">
         <h4 class="cat_name">Science</h4>
          <h5 class="see_more">See all</h5>
        </div>

        <Carousel
          ref={carouselRef}
         breakPoints={breakPoints}
         pagination={false} 
         enableAutoPlay 
         autoPlaySpeed={4000} 
         showArrows={false}
         onNextEnd={({ index }) => {
          clearTimeout(resetTimeout);
          resetTimeout = setTimeout(() => {
              carouselRef?.current?.goTo(0);
          }, 4000); // same time
        }}
         > 
        <Card i_des="Measuring!" i_time="01:12"/>
        <Card i_des="Rounding Numbers for Kids" i_time="01:24"/>
        <Card i_des="Perimeter for Kids" i_time="01:24"/>
        <Card i_des="Measuring!" i_time="01:24"/>
        <Card i_des="Measuring!" i_time="01:24"/>
       </Carousel>
       </div>



       <div>
        <div class="cat_heading">
         <h4 class="cat_name">Comprehension</h4>
          <h5 class="see_more">See all</h5>
        </div>

        <Carousel
          ref={carouselRef}
         breakPoints={breakPoints}
         pagination={false} 
         enableAutoPlay 
         autoPlaySpeed={4000} 
         showArrows={false}
         onNextEnd={({ index }) => {
          clearTimeout(resetTimeout);
          resetTimeout = setTimeout(() => {
              carouselRef?.current?.goTo(0);
          }, 4000); // same time
        }}
         > 
        <Card i_des="Measuring!" i_time="01:12"/>
        <Card i_des="Rounding Numbers for Kids" i_time="01:24"/>
        <Card i_des="Perimeter for Kids" i_time="01:24"/>
        <Card i_des="Measuring!" i_time="01:24"/>
        <Card i_des="Measuring!" i_time="01:24"/>
       </Carousel>
       </div>


       <div>
        <div class="cat_heading">
         <h4 class="cat_name">Art</h4>
          <h5 class="see_more">See all</h5>
        </div>

        <Carousel
          ref={carouselRef}
         breakPoints={breakPoints}
         pagination={false} 
         enableAutoPlay 
         autoPlaySpeed={4000} 
         showArrows={false}
         onNextEnd={({ index }) => {
          clearTimeout(resetTimeout);
          resetTimeout = setTimeout(() => {
              carouselRef?.current?.goTo(0);
          }, 4000); // same time
        }}
         > 
        <Card i_des="Measuring!" i_time="01:12"/>
        <Card i_des="Rounding Numbers for Kids" i_time="01:24"/>
        <Card i_des="Perimeter for Kids" i_time="01:24"/>
        <Card i_des="Measuring!" i_time="01:24"/>
        <Card i_des="Measuring!" i_time="01:24"/>
       </Carousel>
       </div>
        <Cookiesunlock />
        
      </div>
    </>
  );
};
export default Videoquiz;
