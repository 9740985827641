const Cookiesunlock = (props)=>{
    return(
        <>
        <div class="unlock_cookies_image tooltip"><img src="./Assets/Images/unblock.png" /> 
           <h5 class="unlock_cookies_image tooltiptext">Use cookies to unblock your phone</h5>
        </div>
            
        </>
    )
}

export default Cookiesunlock; 