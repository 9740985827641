import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";

const Home =()=>{
  
    return(
        <>
	      
        </>
    )
}
export default Home;