const VideoCat = (props)=>{
    return(
        <>
        <div className="catCantainer" style={{display:'flex', marginBottom:'8px', boxShadow: "1px 2px 9px #080b2880",
           alignItems:'center', justifyContent:'space-between', background:'#fff', borderRadius:'10px',  padding: "18px 20px",  }}>
            <h3 class="catTitle">{props.catTitle}<br /><h6>{props.catdes}</h6></h3>

            <img src={props.imgcate}/>
        </div>
            
        </>
    )
}

export default VideoCat; 