import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import VideoCat from "../Components/VideoCat";
import Tab from "../Components/Tab";
import Avtar from "../Components/Avtar";
import Cookiesunlock from "../Components/Cookiesunlock";
// import Arithmetic from './Assets/Images/Arithmetic.png';

const Video = () => {
  return (
    <>
      <div
        className=""
        style={{
          backgroundColor: "#f5f5f5",
          
          borderRadius: 10,
          padding: "18px 20px",
          width: "40%",
          margin: "auto",
          
        }}
      >
        <Tab /> 
        <Avtar />
        <VideoCat catTitle="Arithmetic" imgcate="./Assets/Images/arithmetic.png" />
        <VideoCat catTitle="Reading Comprehension" catdes="/ No more cookies available today /" imgcate="./Assets/Images/reading.png" />
        <VideoCat catTitle="Logic Exercises" imgcate="./Assets/Images/Logic.png" />
        <VideoCat catTitle="Vocabulary" imgcate="./Assets/Images/Vocabulary.png" />
        <Link to="/Videoquiz"><VideoCat catTitle="Video Quiz " imgcate="./Assets/Images/Video.png" /></Link>
        
        <Cookiesunlock />
      </div>
    </>
  );
};
export default Video;
