const Card = (props)=>{
    return(
        <>
         <div class="cat_video">
          <iframe src="https://www.youtube.com/embed/uONIJ5TQ2DA" frameborder="0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media;"></iframe>  
          <div class="v_time">{props.i_time}</div> 
          <p>{props.i_des}</p> 
         </div>
       
        </>

    )
}

export default Card;